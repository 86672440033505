import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PagedApiResponse } from '@fmnts/api';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { Observable } from 'rxjs';
import * as Api from './api-model';

@Injectable({ providedIn: 'root' })
export class TerritoryApi {
  private readonly http = inject(HttpClient);
  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly apiConfig = inject(ApiConfigService);
  // TODO: MODIFY WHEN DONE
  public static readonly rootUrl = '/v2/territory';

  public getTerritoryList(
    filters: Readonly<Api.TerritoryListQuery> = {},
  ): Observable<PagedApiResponse<Api.Territory>> {
    const url = this.apiConfig.buildCockpitApiUrl([
      TerritoryApi.rootUrl,
      'territories',
      filters.region,
      filters.level,
    ]);
    const params = this.apiHelper.makeParams({
      page_size: 20,
      page: filters.page,
      search: filters.search,
    });

    return this.http.get<PagedApiResponse<Api.Territory>>(url, {
      params,
    });
  }

  getRegions(): Observable<ReadonlyArray<Api.Region>> {
    const url = this.apiConfig.buildCockpitApiUrl([
      TerritoryApi.rootUrl,
      'regions',
    ]);

    return this.http.get<ReadonlyArray<Api.Region>>(url);
  }
}
