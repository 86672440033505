/* eslint-disable @angular-eslint/no-host-metadata-property */

import {
  booleanAttribute,
  Directive,
  HostBinding,
  Input,
  numberAttribute,
  signal,
} from '@angular/core';

/** @deprecated */
export type LegacyButtonFill = 'clear' | 'outline' | 'solid';
/** @deprecated */
export type LegacyButtonShape = 'rounded' | 'pill';

export type ButtonVariant = 'filled' | 'stroked' | 'plain';

@Directive({
  host: {
    '[attr.disabled]': 'disabled || null',
    '[attr.aria-disabled]': 'disabled.toString()',
    // User-specified tabindex is ignored when disabled for
    // consistency with native buttons where even though they
    // have an index, they're not tabbable.
    '[attr.tabindex]': 'disabled ? -1 : tabIndex',
  },
})
export class ButtonBaseComponent {
  @Input() get variant(): ButtonVariant {
    return this._variant();
  }
  set variant(value: ButtonVariant) {
    this._variant.set(value);
  }
  private readonly _variant = signal<ButtonVariant>('plain');

  /**
   * @deprecated use {@link ButtonBaseComponent.variant}.
   */
  @Input()
  set fill(value: LegacyButtonFill) {
    this._variant.set(_mapFillToVariant(value));
  }

  /**
   * Set to `true` to disable the button.
   *
   * **Notice:** On `<a />` tags this won't disable the navigation,
   * especially when used with `[routerLink]`. The correct way to disable
   * the navigation would be
   * ```html
   * <a
   *  [routerLink]="disabled ? null : [...]"
   *  [disable]="disabled"
   * >Link</a>
   * ```
   */
  @Input({ transform: booleanAttribute })
  @HostBinding('class.fmnts-button--disabled')
  @HostBinding('class.disabled')
  disabled = false;

  /** Tabindex of the button. */
  @Input({ transform: numberAttribute }) tabIndex?: number;
}

function _mapFillToVariant(value: LegacyButtonFill): ButtonVariant {
  switch (value) {
    case 'solid':
      return 'filled';
    case 'outline':
      return 'stroked';
    default:
      return 'plain';
  }
}
