import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Direction } from '@angular/cdk/bidi';
import { ViewContainerRef } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as Arr from 'effect/Array';
import * as Brand from 'effect/Brand';

export type ToastActionId = string & Brand.Brand<'ToastActionId'>;
export const ToastActionId = Brand.nominal<ToastActionId>();

export interface ToastAction {
  /** Identifier with which the toast will be dismissed when clicked. */
  actionId: ToastActionId;
  content: string;
}

export type ToastType = 'info' | 'success' | 'warning' | 'error';

type ToastText = { message: string };
type ToastTitle = { title: string };
type ToastContent = ToastText | ToastTitle | (ToastText & ToastTitle);

interface BaseTextToastData {
  readonly type: ToastType;
  readonly icon?: IconProp;
  readonly actions?: Arr.NonEmptyReadonlyArray<ToastAction>;
}

export type TextToastData = BaseTextToastData & ToastContent;
export type NormalizedTextToastData = BaseTextToastData &
  Partial<ToastText> &
  Partial<ToastTitle>;

/**
 * Base interface for a toast component that can be instantiated from data.
 */
export interface TextToast {
  data: TextToastData;
}

/** Event that is emitted when a toast is dismissed. */
export interface ToastDismiss<TResult> {
  /** Whether the toast was dismissed using the action button. */
  dismissedByAction: boolean;
  /** Result with which the toast was dismissed. */
  result: TResult | undefined;
}

/** Possible values for horizontalPosition on ToastConfig. */
export type ToastHorizontalPosition =
  | 'start'
  | 'center'
  | 'end'
  | 'left'
  | 'right';

/** Possible values for verticalPosition on ToastConfig. */
export type ToastVerticalPosition = 'top' | 'bottom';

/**
 * Configuration used when opening a toast.
 */
export class ToastConfig<D = any> {
  /** The politeness level for the MatAriaLiveAnnouncer announcement. */
  politeness?: AriaLivePoliteness = 'assertive';

  /**
   * Message to be announced by the LiveAnnouncer. When opening a toast without a custom
   * component or template, the announcement message will default to the specified message.
   */
  announcementMessage?: string = '';

  /**
   * The view container that serves as the parent for the toast for the purposes of dependency
   * injection. Note: this does not affect where the toast is inserted in the DOM.
   */
  viewContainerRef?: ViewContainerRef;

  /** The length of time in milliseconds to wait before automatically dismissing the toast. */
  duration?: number | undefined = 6_000;

  /** Extra CSS classes to be added to the toast container. */
  panelClass?: string | string[];

  /** Text layout direction for the toast. */
  direction?: Direction;

  /** Data being injected into the child component. */
  data?: D | null = null;

  /** The horizontal position to place the toast. */
  horizontalPosition?: ToastHorizontalPosition = 'right';

  /** The vertical position to place the toast. */
  verticalPosition?: ToastVerticalPosition = 'bottom';
}
