import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import * as P from 'effect/Predicate';
import { ToastRef } from './toast-component-ref';
import {
  NormalizedTextToastData,
  TextToast,
  TextToastData,
  ToastAction,
  ToastActionId,
} from './toast.model';
import { TOAST_DATA, TOAST_DURATION } from './toast.tokens';

@Component({
  selector: 'fmnts-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('timeoutTrigger', [
      state('*', style({ width: '0' })),
      transition(
        '* => run',
        [animate('{{ time }}ms', style({ width: '100%' }))],
        { params: { time: 0 } },
      ),
    ]),
  ],
})
export class ToastComponent implements TextToast, AfterViewInit {
  protected readonly toastRef =
    inject<ToastRef<ToastComponent, ToastActionId>>(ToastRef);
  readonly data = inject<TextToastData>(TOAST_DATA);
  protected readonly _data: NormalizedTextToastData = this.data;
  protected readonly duration =
    inject(TOAST_DURATION, { optional: true }) ?? null;
  private readonly _cd = inject(ChangeDetectorRef);

  @HostBinding('class.fmnts-toast') protected readonly componentClass =
    'fmnts-toast';
  @HostBinding('class') get hostClasses(): string {
    return classnames([
      this.componentClass,
      `${this.componentClass}--${this.data.type}`,
    ]);
  }

  protected _timeoutState = 'new';

  protected readonly iconClose = faTimes;
  protected readonly isNumber = P.isNumber;

  ngAfterViewInit(): void {
    // For some reason Angular does not animate this using :enter
    // when a previous toast was shown (e.g. using toast service).
    // so instead we offset a change, so that change detection
    // first resets the timeout bar and start the animation later.
    // If you know a better way, pls fix it^^
    setTimeout(() => {
      this._timeoutState = 'run';
      this._cd.markForCheck();
    }, 0);
  }

  /** Performs the close on the toast. */
  close(): void {
    this.toastRef.dismiss();
  }

  closeWithAction(action: ToastAction): void {
    this.toastRef.dismissWithAction(action.actionId);
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.data.actions;
  }
}
