<div class="fmnts-toast__inner">
  <fmnts-toast-content [type]="_data.type" [badgeIcon]="_data.icon">
    @if (_data.title) {
      <fmnts-toast-title>
        {{ _data.title }}
      </fmnts-toast-title>
    }

    @if (_data.message) {
      <fmnts-toast-text>
        {{ _data.message }}
      </fmnts-toast-text>
    }
    @if (_data.actions) {
      <div class="fmnts-toast__actions" fmntsToastActions>
        @for (action of _data.actions; track action.actionId) {
          <button
            fmnts-button
            variant="plain"
            type="button"
            class="fmnts-toast-action"
            (click)="closeWithAction(action)"
          >
            {{ action.content }}
          </button>
        }
      </div>
    }
  </fmnts-toast-content>

  <button
    fmnts-icon-button
    size="md"
    type="button"
    (click)="close()"
    data-testid="toast-dismiss-btn"
  >
    <fa-icon [icon]="iconClose" />
  </button>
</div>

@if (isNumber(duration)) {
  <div
    [@timeoutTrigger]="{
      value: _timeoutState,
      params: { time: duration },
    }"
    class="fmnts-toast__timeout"
  ></div>
}
