import {
  EnvironmentProviders,
  Injectable,
  InjectionToken,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  AbstractLogDriver,
  LogDriverFeature,
  LogDriverFeatureKind,
  LogEvent,
  LogLevel,
  LogMessage,
  minLogLevelFilter,
  provideLogDriver,
} from '@fmnts/common/log';
import * as Sentry from '@sentry/angular';

interface SentryLogDriverConfig {
  /** Level at which log events are added as breadcrumbs. */
  minimumBreadcrumbLevel: LogLevel;
  /** Level at which log events are captured using Sentrys `captureMessage` method. */
  minimumEventLevel: LogLevel;
}

/**
 * DI token for the configuration of sentry log driver.
 */
const SENTRY_LOG_DRIVER_CONFIG = new InjectionToken<SentryLogDriverConfig>(
  '@formunauts.shared.vendors.sentry.log-driver',
);

/**
 * The sentry log drivers forwards log events to sentry.
 */
@Injectable()
export class SentryLogDriver extends AbstractLogDriver {
  private readonly config = inject(SENTRY_LOG_DRIVER_CONFIG);
  private readonly mapper = inject(LogEventToSentryMapper);

  private readonly shouldAddBreadcrumb = minLogLevelFilter(
    this.config.minimumBreadcrumbLevel,
  );
  private readonly shouldCaptureEvent = minLogLevelFilter(
    this.config.minimumEventLevel,
  );

  override write(log: LogEvent): void {
    if (this.shouldCaptureEvent(log)) {
      this.captureEvent(log);
    } else if (this.shouldAddBreadcrumb(log)) {
      this.addBreadcrumb(log);
    }
  }

  private addBreadcrumb(log: LogEvent): void {
    Sentry.addBreadcrumb(this.mapper.mapToBreadcrumb(log));
  }

  private captureEvent(log: LogEvent): void {
    Sentry.captureMessage(
      log.message,
      this.mapper.mapToSeverityLevel(log.level),
    );
  }
}

/**
 * Util for mapping log events to sentry models.
 */
@Injectable()
class LogEventToSentryMapper {
  mapToBreadcrumb(log: LogEvent): Sentry.Breadcrumb {
    return {
      category: log.category,
      timestamp: this.mapToTimestamp(log.timestamp),
      message: log.message,
      level: this.mapToSeverityLevel(log.level),
    };
  }

  mapToSeverityLevel(level: LogLevel): Sentry.SeverityLevel {
    switch (level) {
      case LogLevel.Debug:
      case LogLevel.Trace:
        return 'debug';
      case LogLevel.Info:
        return 'info';
      case LogLevel.Error:
        return 'error';
      case LogLevel.Warn:
        return 'warning';
    }
  }

  private mapToTimestamp(timestamp: Date) {
    return timestamp.valueOf() / 1000;
  }
}

/**
 * Sets up providers for the sentry log driver.
 *
 * @param config Configuration for the sentry log driver
 * @param features Set of additional features
 */
export function provideSentryLogDriver(
  config: SentryLogDriverConfig,
  ...features: LogDriverFeature<LogDriverFeatureKind>[]
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideLogDriver(SentryLogDriver, ...features),
    LogEventToSentryMapper,
    { provide: SENTRY_LOG_DRIVER_CONFIG, useValue: config },
  ]);
}

const sentryScope = '<sentry>';

/**
 * Utility function to create a log message from a sentry breadcrumb.
 *
 * @param breadcrumb Sentry breadcrumb
 */
export function logMessageFromSentryBreadcrumb(
  breadcrumb: Sentry.Breadcrumb,
): LogMessage {
  return {
    message: breadcrumb.message ?? 'sentry breadcrumb',
    category: breadcrumb.category,
    scope: sentryScope,
    level: logLevelFromSentrySeverity(breadcrumb.level),
  };
}

function logLevelFromSentrySeverity(s?: Sentry.SeverityLevel): LogLevel {
  switch (s) {
    case 'error':
    case 'fatal':
      return LogLevel.Error;
    case 'info':
    case 'log':
      return LogLevel.Info;
    case 'warning':
      return LogLevel.Warn;
    case 'debug':
    default:
      return LogLevel.Debug;
  }
}

/**
 * Filters log events that were created by
 * {@link logMessageFromSentryBreadcrumb}.
 */
export function filterEventsFromSentryBreadcrumbs(event: LogEvent): boolean {
  return event.scope !== sentryScope;
}
