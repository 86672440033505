import { animate, AnimationBuilder, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ThemeDurationToken,
  timingByThemeTokens,
} from '@fmnts/components/core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

enum Direction {
  Left = 'left',
  Right = 'right',
}

/**
 * Component to render a group of tabs.
 */
@Component({
  selector: 'fmnts-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent implements AfterViewInit {
  private scrollable = signal(false);

  protected scrollableLeft$ = new BehaviorSubject<boolean>(false);

  protected scrollableRight$ = new BehaviorSubject<boolean>(false);

  /** @internal */
  protected readonly _iconScrollBack = faChevronLeft;
  /** @internal */
  protected readonly _iconScrollForward = faChevronRight;

  private scrollPosition = 0;

  private readonly SCROLLING_SPEED: ThemeDurationToken = 'duration.short-2';

  @ViewChild('nav', { static: true }) nav!: ElementRef<HTMLElement>;

  @ViewChild('tabsContainer', { static: true })
  tabContainer!: ElementRef<HTMLDivElement>;

  @ViewChild('tabs', { static: true }) tabs!: ElementRef<HTMLDivElement>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkAvailableSpace();
  }

  constructor(
    private animationBuilder: AnimationBuilder,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.checkAvailableSpace();
    this.cd.detectChanges();
  }

  /**
   * Update ScrollPosition
   * @param direction
   */
  public updateTabScrollPosition(direction: `${Direction}`): void {
    const scrollDistance = this.calculateScrollPosition(direction);

    const translateX = direction === 'right' ? -scrollDistance : scrollDistance;

    this.scroll(translateX);
    this.checkScrollableDirections();
  }

  /**
   * Check whether or not to show navigation-arrows
   */
  private checkAvailableSpace(): void {
    this.scrollable.set(
      this.tabs.nativeElement.scrollWidth > this.nav.nativeElement.offsetWidth,
    );
    this.checkScrollableDirections();
  }

  /**
   * Check which navigation-arrow should be shown
   */
  private checkScrollableDirections() {
    if (this.scrollable() === true) {
      this.scrollableLeft$.next(this.scrollPosition > 0);
      this.scrollableRight$.next(
        this.scrollPosition < this.maxScrollDistance(),
      );
    }
  }

  /**
   * @param container width of container
   * @returns Half the size of the container
   */
  private calculateScrollStep(container: number): number {
    return container / 2;
  }

  /**
   * Calculate maximum scroll distance
   * @returns
   */
  private maxScrollDistance(): number {
    const lengthOfTabList = this.tabs.nativeElement.scrollWidth;
    const viewLength = this.tabContainer.nativeElement.offsetWidth;

    return lengthOfTabList - viewLength || 0;
  }

  /**
   *
   * @param direction `left` or `right`
   * @returns updated scroll-position, used for scroll-animation
   */
  private calculateScrollPosition(direction: `${Direction}`): number {
    const scrollStep = this.calculateScrollStep(
      this.tabContainer.nativeElement.offsetWidth,
    );
    if (direction === 'left') {
      if (this.scrollPosition < scrollStep) {
        this.scrollPosition -= this.scrollPosition;
        return this.scrollPosition;
      } else {
        this.scrollPosition -= scrollStep;
        return -this.scrollPosition;
      }
    }
    if (direction === 'right') {
      if (this.maxScrollDistance() - this.scrollPosition > scrollStep) {
        this.scrollPosition += scrollStep;
        return this.scrollPosition;
      } else {
        this.scrollPosition = this.maxScrollDistance();
        return this.maxScrollDistance();
      }
    }

    return 0;
  }

  /**
   * Calculate position, if clicked on a label
   * @param event MouseEvent, containing the label which was clicked.
   */
  public tabsInnerClicked(event: MouseEvent): void {
    const tabPosition = (event.target as HTMLElement).offsetLeft;
    const viewLength = this.tabContainer.nativeElement.offsetWidth;

    // If we click on a tab on the right side of the screen, scroll to the right
    if (tabPosition > viewLength / 2) {
      const distance = this.calculateScrollPosition('right');
      this.scroll(-distance);
    }
    // Otherwise scroll to the left
    if (tabPosition < viewLength / 2) {
      const distance = this.calculateScrollPosition('left');
      this.scroll(distance);
    }
    this.checkScrollableDirections();
  }

  /**
   * Scroll
   * Uses AnimationBuilder to create a scrolling animation
   * @param translateX
   */
  scroll(translateX: number): void {
    const element = this.tabs.nativeElement;

    const animation = this.animationBuilder.build([
      style('*'),
      animate(
        timingByThemeTokens({ duration: this.SCROLLING_SPEED }),
        style({
          transform: `translateX(${translateX}px)`,
        }),
      ),
    ]);

    const player = animation.create(element);
    player.play();
  }
}
