import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays the toast text.
 */
@Component({
  selector: 'fmnts-toast-text, [fmnts-toast-text]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./toast-text.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToastTextComponent {
  @HostBinding('class.fmnts-toast-text')
  private readonly componentClass = 'fmnts-toast-text';
}
