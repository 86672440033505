/* eslint-disable @angular-eslint/component-selector */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';
import { ButtonBaseComponent } from './button-base';

export type ButtonAction = 'primary' | 'normal' | 'destructive';
export type ButtonSize = 'md' | 'lg';

/**
 * Displays a button.
 */
@Component({
  selector: `button[fmnts-button], a[fmnts-button]`,
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends ButtonBaseComponent {
  @Input()
  get action(): ButtonAction {
    return this._action();
  }
  set action(value: ButtonAction) {
    this._action.set(value);
  }
  private readonly _action = signal<ButtonAction>('normal');

  // todo(design-system): there is no 'sm' variant atm
  /** Size of the button. */
  @Input({ transform: _mapToSize })
  get size(): ButtonSize {
    return this._size();
  }
  set size(value: ButtonSize) {
    this._size.set(value);
  }
  private readonly _size = signal<ButtonSize>('md');

  // Legacy inputs
  /**
   * @deprecated use {@link ButtonComponent.action}.
   */
  @Input()
  set color(value: ThemeColor | undefined) {
    this._action.set(_mapToAction(value));
  }

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      `fmnts-button`,
      `fmnts-button--${this._size()}`,
      `fmnts-button--${this.variant}-${this._action()}`,
    ]);
  }
}

function _mapToAction(value: ThemeColor | undefined): ButtonAction {
  switch (value) {
    case 'primary':
      return 'primary';
    case 'danger':
      return 'destructive';
    default:
      return 'normal';
  }
}

function _mapToSize(value: ButtonSize | 'sm'): ButtonSize {
  return value === 'lg' ? 'lg' : 'md';
}
