import {
  Component,
  HostBinding,
  Input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';

export type IconBadgeSize = 'sm' | 'md' | 'lg';

/**
 * Displays an icon within a badge.
 */
@Component({
  selector: 'fmnts-icon-badge',
  template: '<ng-content />',
  styleUrls: ['./icon-badge.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class IconBadgeComponent {
  private readonly componentClass = 'fmnts-icon-badge';

  @Input({ required: true })
  get size(): IconBadgeSize {
    return this._size();
  }
  set size(value: IconBadgeSize) {
    this._size.set(value);
  }
  private readonly _size = signal<IconBadgeSize>('md');

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this.componentClass,
      `${this.componentClass}--${this._size()}`,
    ]);
  }
}
