import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EmbeddedViewRef,
  HostBinding,
  Inject,
  Injector,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { first } from 'rxjs';
import { ToastRef } from './toast-component-ref';
import { ToastContainerComponent } from './toast-container';
import { ToastComponent } from './toast.component';
import { TextToast, TextToastData, ToastConfig } from './toast.model';
import {
  FMNTS_TOAST_DEFAULT_OPTIONS,
  TOAST_DATA,
  TOAST_DURATION,
} from './toast.tokens';

/**
 * Displays a group of toasts.
 */
@Component({
  selector: 'fmnts-toast-group',
  templateUrl: './toast-group.component.html',
  styleUrls: ['./toast-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastGroupComponent {
  @HostBinding('class.fmnts-toast-group')
  private readonly componentClass = 'fmnts-toast-group';

  /** container that holds the toasts */
  @ViewChild('toasts', { read: ViewContainerRef, static: true })
  protected _toasts!: ViewContainerRef;

  private _simpleToastComponent: ComponentType<ToastComponent> = ToastComponent;

  constructor(
    private _injector: Injector,
    private _cd: ChangeDetectorRef,
    private _live: LiveAnnouncer,
    @Inject(FMNTS_TOAST_DEFAULT_OPTIONS)
    private _defaultConfig: ToastConfig,
  ) {}

  /**
   * Creates and dispatches a toast with a custom component for the content.
   *
   * @param component Component to be instantiated.
   * @param config Extra configuration for the toast.
   *
   * @returns
   * Reference to the toast
   */
  openFromComponent<T, D = any, TResult = unknown>(
    component: ComponentType<T>,
    config?: ToastConfig<D>,
  ): ToastRef<T, TResult> {
    return this._attach(component, config);
  }

  /**
   * Creates and dispatches a toast with a custom template for the content.
   *
   * @param template Template to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromTemplate<D = any, TResult = unknown>(
    template: TemplateRef<any>,
    config?: ToastConfig<D>,
  ): ToastRef<EmbeddedViewRef<any>, TResult> {
    return this._attach(template, config);
  }

  /**
   * Opens a toast from static data.
   *
   * @param data Data to be passed to toast
   * @param config Additional toast config
   *
   * @returns
   * Reference to the toast
   */
  open<D = any, TResult = unknown>(
    data: TextToastData,
    config?: ToastConfig<D>,
  ): ToastRef<TextToast, TResult> {
    const _config: ToastConfig<TextToastData> = {
      ...config,
      // Since the user doesn't have access to the component, we can
      // override the data to pass in our own message and action.
      data,
    };

    return this.openFromComponent(this._simpleToastComponent, _config);
  }

  /**
   * Attaches a component / template as the `content` to a new `ToastContainer`
   * with the provided `userConfig`.
   *
   * @param content
   * @param userConfig
   *
   * @returns
   * `ToastRef` to the new created toast.
   */
  _attach<T, D = any, TResult = unknown>(
    content: ComponentType<T>,
    userConfig?: ToastConfig<D>,
  ): ToastRef<T, TResult>;
  _attach<T, D = any, TResult = unknown>(
    content: TemplateRef<T>,
    userConfig?: ToastConfig<D>,
  ): ToastRef<EmbeddedViewRef<any>, TResult>;
  _attach(
    content: ComponentType<unknown> | TemplateRef<unknown>,
    userConfig?: ToastConfig,
  ): ToastRef<unknown> | ToastRef<EmbeddedViewRef<any>> {
    const config: ToastConfig<unknown> = {
      ...new ToastConfig(),
      ...this._defaultConfig,
      ...userConfig,
    };
    const containerRef = this._attachToastContainer(config);
    const container = containerRef.instance;
    const toastRef = new ToastRef<unknown>(container);
    const injector = this._createInjector(config, toastRef);

    if (content instanceof TemplateRef) {
      const portal = new TemplatePortal<unknown>(
        content,
        container._viewContainerRef,
        {
          $implicit: config.data,
          toastRef,
        },
        injector,
      );

      toastRef.instance = container.attachTemplatePortal(portal);
    } else {
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<unknown>(portal);

      // We can't pass this via the injector, because the injector is created earlier.
      toastRef.instance = contentRef.instance;
    }

    this._handleCleanup(toastRef, containerRef);
    this._animateToast(toastRef, config);
    return toastRef;
  }

  /**
   * Attaches the toast container component to view container.
   */
  private _attachToastContainer(
    config: ToastConfig,
  ): ComponentRef<ToastContainerComponent> {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{ provide: ToastConfig, useValue: config }],
    });

    return this._toasts.createComponent(ToastContainerComponent, {
      injector,
    });
  }

  /** Animates the old toast out and the new one in. */
  private _animateToast(toastRef: ToastRef<any>, config: ToastConfig) {
    // When the toast is dismissed, clear the reference to it.
    toastRef.afterDismissed().subscribe(() => {
      if (config.announcementMessage) {
        this._live.clear();
      }
    });

    // If no toast is in view, enter the new toast.
    toastRef.containerInstance.enter();

    // If a dismiss timeout is provided, set up dismiss based on after the toast is opened.
    if (config.duration && config.duration > 0) {
      toastRef.afterOpened().subscribe(() => {
        if (config.duration) {
          toastRef._dismissAfter(config.duration);
        }
      });
    }
  }

  private _handleCleanup(
    toastRef: ToastRef<any>,
    containerRef: ComponentRef<ToastContainerComponent>,
  ) {
    // TODO: think about destroying toast container ref, once toast is destroyed.
    toastRef
      .afterDismissed()
      .pipe(first())
      .subscribe(() => {
        const i = this._toasts.indexOf(containerRef.hostView);
        if (i !== -1) {
          this._toasts.remove(i);
          this._cd.detectChanges();
        }
      });
  }

  /**
   * Creates an injector to be used inside of a toast component.
   * @param config Config that was used to create the toast.
   * @param toastRef Reference to the toast.
   */
  private _createInjector<T>(
    config: ToastConfig<unknown>,
    toastRef: ToastRef<T>,
  ): Injector {
    const userInjector = config.viewContainerRef?.injector;

    return Injector.create({
      parent: userInjector || this._injector,
      providers: [
        { provide: ToastRef, useValue: toastRef },
        { provide: TOAST_DATA, useValue: config.data },
        { provide: TOAST_DURATION, useValue: config.duration },
      ],
    });
  }
}
