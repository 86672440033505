import {
  ErrorHandler,
  Injectable,
  InjectionToken,
  inject,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { logger } from '@fmnts/common/log';
import { signalStore, withState } from '@ngrx/signals';
import * as Sentry from '@sentry/angular';
import * as Fn from 'effect/Function';
import * as Predicate from 'effect/Predicate';
import { first } from 'rxjs';
import { SentryRef } from './sentry-ref.service';

export abstract class SentryErrorHandlerConfig {
  /** @default true */
  abstract readonly logErrors?: boolean;
}

interface SentryErrorHandlerState {
  /** Whether to log errors. */
  logErrors: boolean;
}

export const SentryErrorHandlerSettings = signalStore(
  { protectedState: false },
  withState<SentryErrorHandlerState>(() =>
    _fromConfig(inject(SentryErrorHandlerConfig, { optional: true }) ?? {}),
  ),
);

function _fromConfig(cfg: SentryErrorHandlerConfig): SentryErrorHandlerState {
  return {
    logErrors: cfg.logErrors ?? true,
  };
}

export type SentryErrorExtractor = (input: unknown) => unknown;
export const SentryErrorHandlerExtractor =
  new InjectionToken<SentryErrorExtractor>(
    '@formunauts.shared.vendors.sentry.error-handler.extractor',
  );

/**
 * Implements Angulars {@link ErrorHandler} interface.
 */
@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  private readonly _log = logger({ name: SentryErrorHandler.name });
  private readonly _settings = inject(SentryErrorHandlerSettings);

  private readonly _extract =
    inject(SentryErrorHandlerExtractor, { optional: true }) ?? Fn.identity;

  /** instance of a sentry error handler. */
  private _errorHandler?: Sentry.SentryErrorHandler;

  constructor() {
    super();

    // create error handler once sentry is stable.
    toObservable(inject(SentryRef).isStable)
      .pipe(first(Predicate.isTruthy))
      .subscribe(() => {
        this._initErrorHandler();
      });
  }

  override handleError(error: unknown): void {
    // When the error handler is already available, use it to handle the error
    if (this._errorHandler) {
      this._errorHandler.handleError(error);
      return;
    }

    // Otherwise fallback to capturing the exception manually
    Sentry.captureException(this._extract(_findOriginalError(error) ?? error));
    if (this._settings.logErrors()) {
      super.handleError(error);
    }
  }

  private _initErrorHandler() {
    if (this._errorHandler) {
      this._log.warn('error handler already initialized');
      return;
    }

    // Once sentry is all set up, create the underlying sentry error handler
    this._errorHandler = Sentry.createErrorHandler({
      // Use `beforeSend` event to handle report dialog
      showDialog: false,
      // Use Angulars error handler for logging errors
      logErrors: this._settings.logErrors(),
      extractor: (error, defaultExtractor) => {
        // Sentrys default extractor tries to unwrap zone.js errors.
        // It might return `null`, in which case we use the original.
        const unwrapped = defaultExtractor(error) ?? error;
        // Now with a potentially unwrapped error, we will run our extractor
        // and Sentrys extractor again, to handle `HttpErrorResponse`s.
        // Again, if that returns a `null`, use the original.
        return defaultExtractor(this._extract(unwrapped)) ?? error;
      },
    });
  }
}

// -------------------------------------------------------------------------------
// Angular Error Handling:
// The following section are helpers to extract the original errors from
// Angular. For more information check the Angular implementation
// https://github.com/angular/angular/blob/main/packages/core/src/error_handler.ts
// https://github.com/angular/angular/blob/main/packages/core/src/util/errors.ts
// -------------------------------------------------------------------------------
const ERROR_ORIGINAL_ERROR = 'ngOriginalError';

function _getOriginalError(error: Error): Error {
  return (error as any)[ERROR_ORIGINAL_ERROR];
}

function _findOriginalError(error: any): Error | null {
  let e = error && _getOriginalError(error);
  while (e && _getOriginalError(e)) {
    e = _getOriginalError(e);
  }

  return e || null;
}
