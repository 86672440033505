import * as Brand from 'effect/Brand';
import * as Data from 'effect/Data';
import * as Equivalence from 'effect/Equivalence';

export type OrganizationId = number & Brand.Brand<'OrganizationId'>;
export const OrganizationId = Brand.nominal<OrganizationId>();

export interface OrganizationRef {
  readonly _tag: 'OrganizationRef';
  readonly id: OrganizationId;
  readonly name: string;
}

export const OrganizationRef = Data.tagged<OrganizationRef>('OrganizationRef');

export const equivalence = Equivalence.mapInput(
  Equivalence.number,
  ({ id }: OrganizationRef) => id,
);
