import { Injectable } from '@angular/core';
import { CustomerApi } from '@fmnts/api';
import { createHttpRequestEffectHandler } from '@fmnts/common/store';
import { fromHttpOrGeneralError } from '@formunauts/shared/errors/domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, concatMap, exhaustMap, of } from 'rxjs';
import { CustomerApiActions, CustomerRootActions } from '../customer.actions';
import { selectAssignedCustomerId } from '../customer.selectors';

@Injectable()
export class CustomerApiEffects {
  getCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerApiActions.getCustomer.request),
      exhaustMap(
        createHttpRequestEffectHandler(CustomerApiActions.getCustomer, {
          run: ({ customerId }) => this.customerApi.get(customerId),
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  fetchAssignedCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerRootActions.setAssignedCustomer),
      concatLatestFrom((action) => this.store.select(selectAssignedCustomerId)),
      concatMap(([{ customerId }]) =>
        customerId
          ? of(CustomerApiActions.getCustomer.request({ customerId }))
          : EMPTY,
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private customerApi: CustomerApi,
    private store: Store,
  ) {}
}
