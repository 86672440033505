/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directionality } from '@angular/cdk/bidi';
import {
  CollectionViewer,
  DataSource,
  _DisposeViewRepeaterStrategy,
  _RecycleViewRepeaterStrategy,
  _VIEW_REPEATER_STRATEGY,
  _ViewRepeater,
  _ViewRepeaterItemChange,
  _ViewRepeaterItemInsertArgs,
  _ViewRepeaterOperation,
  isDataSource,
} from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentChecked,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Input,
  IterableChangeRecord,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { isNotNullish } from '@fmnts/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  isObservable,
  of,
  takeUntil,
} from 'rxjs';
import { FmntsColumnDefDirective } from './cell.directive';
import {
  BaseRowDef,
  FmntsCellOutletDirective,
  FmntsFooterRowDefDirective,
  FmntsHeaderRowDefDirective,
  FmntsNoDataRowDirective,
  FmntsRowDefDirective,
} from './row.directive';
import {
  getTableDuplicateColumnNameError,
  getTableMissingMatchingRowDefError,
  getTableMissingRowDefsError,
  getTableMultipleDefaultRowDefsError,
  getTableUnknownColumnError,
  getTableUnknownDataSourceError,
} from './table-errors';
import { FmntsCellOutletRowContext } from './table.model';
import { FMNTS_TABLE } from './table.tokens';

/**
 * Enables the recycle view repeater strategy, which reduces rendering latency. Not compatible with
 * tables that animate rows.
 */
@Directive({
  selector: 'fmnts-table[recycleRows], table[fmnts-table][recycleRows]',
  providers: [
    {
      provide: _VIEW_REPEATER_STRATEGY,
      useClass: _RecycleViewRepeaterStrategy,
    },
  ],
  standalone: true,
})
export class FmntsRecycleRowsDirective {}

/** Interface used to provide an outlet for rows to be inserted into. */
export interface RowOutlet {
  viewContainer: ViewContainerRef;
}

/** Possible types that can be set as the data source for a `FmntsTable`. */
export type FmntsTableDataSourceInput<T> =
  | readonly T[]
  | DataSource<T>
  | Observable<readonly T[]>;

/**
 * Provides a handle for the table to grab the view container's ng-container to insert data rows.
 *
 * @internal
 */
@Directive({
  selector: '[rowOutlet]',
  standalone: true,
})
export class DataRowOutletDirective implements RowOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef,
  ) {}
}

/**
 * Provides a handle for the table to grab the view container's ng-container to insert the header.
 *
 * @internal
 */
@Directive({
  selector: '[headerRowOutlet]',
  standalone: true,
})
export class HeaderRowOutletDirective implements RowOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef,
  ) {}
}

/**
 * Provides a handle for the table to grab the view container's ng-container to insert the footer.
 *
 * @internal
 */
@Directive({
  selector: '[footerRowOutlet]',
  standalone: true,
})
export class FooterRowOutletDirective implements RowOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef,
  ) {}
}

/**
 * Provides a handle for the table to grab the view
 * container's ng-container to insert the no data row.
 *
 * @internal
 */
@Directive({
  selector: '[noDataRowOutlet]',
  standalone: true,
})
export class NoDataRowOutletDirective implements RowOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef,
  ) {}
}

/**
 * The table template that can be used by the fmnts-table.
 *
 * @internal
 */
const FMNTS_TABLE_TEMPLATE =
  // Note that according to MDN, the `caption` element has to be projected as the **first**
  // element in the table. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/caption
  `
  <ng-content select="caption"></ng-content>
  <ng-content select="colgroup, col"></ng-content>
  <ng-container headerRowOutlet></ng-container>
  <ng-container rowOutlet></ng-container>
  <ng-container noDataRowOutlet></ng-container>
  <ng-container footerRowOutlet></ng-container>
`;

/**
 * Interface used to conveniently type the possible context interfaces for the render row.
 */
export type RowContext<T> = FmntsCellOutletRowContext<T>;

/**
 * Class used to conveniently type the embedded view ref for rows with a context.
 *
 * @internal
 */
abstract class RowViewRef<T> extends EmbeddedViewRef<RowContext<T>> {}

/**
 * Set of properties that represents the identity of a single rendered row.
 *
 * When the table needs to determine the list of rows to render, it will do so by iterating through
 * each data object and evaluating its list of row templates to display (when multiTemplateDataRows
 * is false, there is only one template per data object). For each pair of data object and row
 * template, a `RenderRow` is added to the list of rows to render. If the data object and row
 * template pair has already been rendered, the previously used `RenderRow` is added; else a new
 * `RenderRow` is * created. Once the list is complete and all data objects have been iterated
 * through, a diff is performed to determine the changes that need to be made to the rendered rows.
 *
 * @internal
 */
export interface RenderRow<T> {
  data: T;
  dataIndex: number;
  rowDef: FmntsRowDefDirective<T>;
}

/**
 * A data table that can render a header row, data rows, and a footer row.
 * Uses the dataSource input to determine the data to be rendered. The data can be provided either
 * as a data array, an Observable stream that emits the data array to render, or a DataSource with a
 * connect function that will return an Observable stream that emits the data array to render.
 */
@Component({
  selector: 'fmnts-table, table[fmnts-table]',
  template: FMNTS_TABLE_TEMPLATE,
  styleUrls: ['./table.component.scss'],
  host: {
    class: 'fmnts-table',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    { provide: FMNTS_TABLE, useExisting: FmntsTableComponent },
    {
      provide: _VIEW_REPEATER_STRATEGY,
      useClass: _DisposeViewRepeaterStrategy,
    },
  ],
  standalone: true,
  imports: [
    HeaderRowOutletDirective,
    DataRowOutletDirective,
    NoDataRowOutletDirective,
    FooterRowOutletDirective,
  ],
})
export class FmntsTableComponent<T>
  implements AfterContentChecked, CollectionViewer, OnDestroy, OnInit
{
  /** Latest data provided by the data source. */
  protected _data!: readonly T[];

  /** Subject that emits when the component has been destroyed. */
  private readonly _onDestroy = new Subject<void>();

  /** List of the rendered rows as identified by their `RenderRow` object. */
  private _renderRows!: RenderRow<T>[];

  /** Subscription that listens for the data provided by the data source. */
  private _renderChangeSubscription!: Subscription | null;

  /**
   * Map of all the user's defined columns (header, data, and footer cell template) identified by
   * name. Collection populated by the column definitions gathered by `ContentChildren` as well as
   * any custom column definitions added to `_customColumnDefs`.
   */
  private _columnDefsByName = new Map<string, FmntsColumnDefDirective>();

  /**
   * Set of all row definitions that can be used by this table. Populated by the rows gathered by
   * using `ContentChildren` as well as any custom row definitions added to `_customRowDefs`.
   */
  private _rowDefs!: FmntsRowDefDirective<T>[];

  /**
   * Set of all header row definitions that can be used by this table. Populated by the rows
   * gathered by using `ContentChildren` as well as any custom row definitions added to
   * `_customHeaderRowDefs`.
   */
  private _headerRowDefs!: FmntsHeaderRowDefDirective[];

  /**
   * Set of all row definitions that can be used by this table. Populated by the rows gathered by
   * using `ContentChildren` as well as any custom row definitions added to
   * `_customFooterRowDefs`.
   */
  private _footerRowDefs!: FmntsFooterRowDefDirective[];

  /** Differ used to find the changes in the data provided by the data source. */
  private _dataDiffer!: IterableDiffer<RenderRow<T>>;

  /** Stores the row definition that does not have a when predicate. */
  private _defaultRowDef!: FmntsRowDefDirective<T> | null;

  /**
   * Column definitions that were defined outside of the direct content children of the table.
   * These will be defined when, e.g., creating a wrapper around the fmnts-table that has
   * column definitions as *its* content child.
   */
  private _customColumnDefs = new Set<FmntsColumnDefDirective>();

  /**
   * Data row definitions that were defined outside of the direct content children of the table.
   * These will be defined when, e.g., creating a wrapper around the fmnts-table that has
   * built-in data rows as *its* content child.
   */
  private _customRowDefs = new Set<FmntsRowDefDirective<T>>();

  /**
   * Header row definitions that were defined outside of the direct content children of the table.
   * These will be defined when, e.g., creating a wrapper around the fmnts-table that has
   * built-in header rows as *its* content child.
   */
  private _customHeaderRowDefs = new Set<FmntsHeaderRowDefDirective>();

  /**
   * Footer row definitions that were defined outside of the direct content children of the table.
   * These will be defined when, e.g., creating a wrapper around the fmnts-table that has a
   * built-in footer row as *its* content child.
   */
  private _customFooterRowDefs = new Set<FmntsFooterRowDefDirective>();

  /** No data row that was defined outside of the direct content children of the table. */
  private _customNoDataRow!: FmntsNoDataRowDirective | null;

  /**
   * Whether the header row definition has been changed. Triggers an update to the header row after
   * content is checked. Initialized as true so that the table renders the initial set of rows.
   */
  private _headerRowDefChanged = true;

  /**
   * Whether the footer row definition has been changed. Triggers an update to the footer row after
   * content is checked. Initialized as true so that the table renders the initial set of rows.
   */
  private _footerRowDefChanged = true;

  /**
   * Cache of the latest rendered `RenderRow` objects as a map for easy retrieval when constructing
   * a new list of `RenderRow` objects for rendering rows. Since the new list is constructed with
   * the cached `RenderRow` objects when possible, the row identity is preserved when the data
   * and row template matches, which allows the `IterableDiffer` to check rows by reference
   * and understand which rows are added/moved/removed.
   *
   * Implemented as a map of maps where the first key is the `data: T` object and the second is the
   * `FmntsRowDef<T>` object. With the two keys, the cache points to a `RenderRow<T>` object that
   * contains an array of created pairs. The array is necessary to handle cases where the data
   * array contains multiple duplicate data objects and each instantiated `RenderRow` must be
   * stored.
   */
  private _cachedRenderRowsMap = new Map<
    T,
    WeakMap<FmntsRowDefDirective<T>, RenderRow<T>[]>
  >();

  /** Whether the table is applied to a native `<table>`. */
  protected _isNativeHtmlTable: boolean;

  /** Whether the no data row is currently showing anything. */
  private _isShowingNoDataRow = false;

  /**
   * Tracking function that will be used to check the differences in data changes. Used similarly
   * to `ngFor` `trackBy` function. Optimize row operations by identifying a row based on its data
   * relative to the function to know if a row should be added/removed/moved.
   * Accepts a function that takes two parameters, `index` and `item`.
   */
  @Input()
  get trackBy(): TrackByFunction<T> | undefined {
    return this._trackByFn;
  }
  set trackBy(fn: TrackByFunction<T> | undefined) {
    if (isNotNullish(fn) && typeof fn !== 'function') {
      console.warn(
        `trackBy must be a function, but received ${JSON.stringify(fn)}.`,
      );
    }
    this._trackByFn = fn;
  }
  private _trackByFn?: TrackByFunction<T>;

  /**
   * The table's source of data, which can be provided in three ways (in order of complexity):
   *   - Simple data array (each object represents one table row)
   *   - Stream that emits a data array each time the array changes
   *   - `DataSource` object that implements the connect/disconnect interface.
   *
   * If a data array is provided, the table must be notified when the array's objects are
   * added, removed, or moved. This can be done by calling the `renderRows()` function which will
   * render the diff since the last table render. If the data array reference is changed, the table
   * will automatically trigger an update to the rows.
   *
   * When providing an Observable stream, the table will trigger an update automatically when the
   * stream emits a new array of data.
   *
   * Finally, when providing a `DataSource` object, the table will use the Observable stream
   * provided by the connect function and trigger updates when that stream emits new data array
   * values. During the table's ngOnDestroy or when the data source is removed from the table, the
   * table will call the DataSource's `disconnect` function (may be useful for cleaning up any
   * subscriptions registered during the connect process).
   */
  @Input()
  get dataSource(): FmntsTableDataSourceInput<T> {
    return this._dataSource;
  }
  set dataSource(dataSource: FmntsTableDataSourceInput<T>) {
    if (this._dataSource !== dataSource) {
      this._switchDataSource(dataSource);
    }
  }
  private _dataSource!: FmntsTableDataSourceInput<T>;

  /**
   * Emits when the table completes rendering a set of data rows based on the latest data from the
   * data source, even if the set of rows is empty.
   */
  @Output()
  readonly contentChanged = new EventEmitter<void>();

  // TODO(table): can this be removed?
  /**
   * Stream containing the latest information on what rows are being displayed on screen.
   * Can be used by the data source to as a heuristic of what data should be provided.
   *
   * @internal
   */
  readonly viewChange = new BehaviorSubject<{ start: number; end: number }>({
    start: 0,
    end: Number.MAX_VALUE,
  });

  // Outlets in the table's template where the header, data rows, and footer will be inserted.
  @ViewChild(DataRowOutletDirective, { static: true })
  _rowOutlet!: DataRowOutletDirective;
  @ViewChild(HeaderRowOutletDirective, { static: true })
  _headerRowOutlet!: HeaderRowOutletDirective;
  @ViewChild(FooterRowOutletDirective, { static: true })
  _footerRowOutlet!: FooterRowOutletDirective;
  @ViewChild(NoDataRowOutletDirective, { static: true })
  _noDataRowOutlet!: NoDataRowOutletDirective;

  /**
   * The column definitions provided by the user that contain what the header, data, and footer
   * cells should render for each column.
   */
  @ContentChildren(FmntsColumnDefDirective, { descendants: true })
  _contentColumnDefs!: QueryList<FmntsColumnDefDirective>;

  /** Set of data row definitions that were provided to the table as content children. */
  @ContentChildren(FmntsRowDefDirective, { descendants: true })
  _contentRowDefs!: QueryList<FmntsRowDefDirective<T>>;

  /** Set of header row definitions that were provided to the table as content children. */
  @ContentChildren(FmntsHeaderRowDefDirective, {
    descendants: true,
  })
  _contentHeaderRowDefs!: QueryList<FmntsHeaderRowDefDirective>;

  /** Set of footer row definitions that were provided to the table as content children. */
  @ContentChildren(FmntsFooterRowDefDirective, {
    descendants: true,
  })
  _contentFooterRowDefs!: QueryList<FmntsFooterRowDefDirective>;

  /** Row definition that will only be rendered if there's no data in the table. */
  @ContentChild(FmntsNoDataRowDirective) _noDataRow!: FmntsNoDataRowDirective;

  constructor(
    protected readonly _differs: IterableDiffers,
    protected readonly _changeDetectorRef: ChangeDetectorRef,
    protected readonly _elementRef: ElementRef<HTMLElement>,
    @Attribute('role') role: string,
    @Optional() protected readonly _dir: Directionality,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(_VIEW_REPEATER_STRATEGY)
    protected readonly _viewRepeater: _ViewRepeater<
      T,
      RenderRow<T>,
      RowContext<T>
    >,
  ) {
    if (!role) {
      this._elementRef.nativeElement.setAttribute('role', 'table');
    }

    this._isNativeHtmlTable =
      this._elementRef.nativeElement.nodeName === 'TABLE';
  }

  ngOnInit(): void {
    if (this._isNativeHtmlTable) {
      this._applyNativeTableSections();
    }

    // Set up the trackBy function so that it uses the `RenderRow` as its identity by default. If
    // the user has provided a custom trackBy, return the result of that function as evaluated
    // with the values of the `RenderRow`'s data and index.
    this._dataDiffer = this._differs
      .find([])
      .create(
        (_i: number, dataRow: RenderRow<T>) =>
          (this.trackBy?.(dataRow.dataIndex, dataRow.data) ??
            dataRow) as IterableDiffer<RenderRow<T>>,
      );
  }

  ngAfterContentChecked(): void {
    // Cache the row and column definitions gathered by ContentChildren and programmatic injection.
    this._cacheRowDefs();
    this._cacheColumnDefs();

    // Make sure that the user has at least added header, footer, or data row def.
    if (
      !this._headerRowDefs.length &&
      !this._footerRowDefs.length &&
      !this._rowDefs.length
    ) {
      throw getTableMissingRowDefsError();
    }

    // Render updates if the list of columns have been changed for the header, row, or footer defs.
    this._renderUpdatedColumns();

    // If the header row definition has been changed, trigger a render to the header row.
    if (this._headerRowDefChanged) {
      this._forceRenderHeaderRows();
      this._headerRowDefChanged = false;
    }

    // If the footer row definition has been changed, trigger a render to the footer row.
    if (this._footerRowDefChanged) {
      this._forceRenderFooterRows();
      this._footerRowDefChanged = false;
    }

    // If there is a data source and row definitions, connect to the data source unless a
    // connection has already been made.
    if (
      this.dataSource &&
      this._rowDefs.length > 0 &&
      !this._renderChangeSubscription
    ) {
      this._observeRenderChanges();
    }
  }

  ngOnDestroy(): void {
    [
      this._rowOutlet.viewContainer,
      this._headerRowOutlet.viewContainer,
      this._footerRowOutlet.viewContainer,
      this._cachedRenderRowsMap,
      this._customColumnDefs,
      this._customRowDefs,
      this._customHeaderRowDefs,
      this._customFooterRowDefs,
      this._columnDefsByName,
    ].forEach((def) => {
      def.clear();
    });

    this._headerRowDefs = [];
    this._footerRowDefs = [];
    this._defaultRowDef = null;
    this._onDestroy.next();
    this._onDestroy.complete();

    if (isDataSource(this.dataSource)) {
      this.dataSource.disconnect(this);
    }
  }

  /**
   * Renders rows based on the table's latest set of data, which was either provided directly as an
   * input or retrieved through an Observable stream (directly or from a DataSource).
   * Checks for differences in the data since the last diff to perform only the necessary
   * changes (add/remove/move rows).
   *
   * If the table's data source is a DataSource or Observable, this will be invoked automatically
   * each time the provided Observable stream emits a new data array. Otherwise if your data is
   * an array, this function will need to be called to render any changes.
   */
  renderRows(): void {
    this._renderRows = this._getAllRenderRows();
    const changes = this._dataDiffer.diff(this._renderRows);
    if (!changes) {
      this._updateNoDataRow();
      this.contentChanged.next();
      return;
    }
    const viewContainer = this._rowOutlet.viewContainer;

    this._viewRepeater.applyChanges(
      changes,
      viewContainer,
      (
        record: IterableChangeRecord<RenderRow<T>>,
        _adjustedPreviousIndex: number | null,
        currentIndex: number | null,
      ) => this._getEmbeddedViewArgs(record.item, currentIndex as number),
      (record) => record.item.data,
      (change: _ViewRepeaterItemChange<RenderRow<T>, RowContext<T>>) => {
        if (
          change.operation === _ViewRepeaterOperation.INSERTED &&
          change.context
        ) {
          this._renderCellTemplateForItem(
            change.record.item.rowDef,
            change.context,
          );
        }
      },
    );

    // Update the meta context of a row's context data (index, count, first, last, ...)
    this._updateRowIndexContext();

    // Update rows that did not get added/removed/moved but may have had their identity changed,
    // e.g. if trackBy matched data on some property but the actual data reference changed.
    changes.forEachIdentityChange(
      (record: IterableChangeRecord<RenderRow<T>>) => {
        const rowView = viewContainer.get(
          record.currentIndex as number,
        ) as RowViewRef<T>;
        rowView.context.$implicit = record.item.data;
      },
    );

    this._updateNoDataRow();

    this.contentChanged.next();
  }

  /** Adds a column definition that was not included as part of the content children. */
  addColumnDef(columnDef: FmntsColumnDefDirective): void {
    this._customColumnDefs.add(columnDef);
  }

  /** Removes a column definition that was not included as part of the content children. */
  removeColumnDef(columnDef: FmntsColumnDefDirective): void {
    this._customColumnDefs.delete(columnDef);
  }

  /** Adds a row definition that was not included as part of the content children. */
  addRowDef(rowDef: FmntsRowDefDirective<T>): void {
    this._customRowDefs.add(rowDef);
  }

  /** Removes a row definition that was not included as part of the content children. */
  removeRowDef(rowDef: FmntsRowDefDirective<T>): void {
    this._customRowDefs.delete(rowDef);
  }

  /** Adds a header row definition that was not included as part of the content children. */
  addHeaderRowDef(headerRowDef: FmntsHeaderRowDefDirective): void {
    this._customHeaderRowDefs.add(headerRowDef);
    this._headerRowDefChanged = true;
  }

  /** Removes a header row definition that was not included as part of the content children. */
  removeHeaderRowDef(headerRowDef: FmntsHeaderRowDefDirective): void {
    this._customHeaderRowDefs.delete(headerRowDef);
    this._headerRowDefChanged = true;
  }

  /** Adds a footer row definition that was not included as part of the content children. */
  addFooterRowDef(footerRowDef: FmntsFooterRowDefDirective): void {
    this._customFooterRowDefs.add(footerRowDef);
    this._footerRowDefChanged = true;
  }

  /** Removes a footer row definition that was not included as part of the content children. */
  removeFooterRowDef(footerRowDef: FmntsFooterRowDefDirective): void {
    this._customFooterRowDefs.delete(footerRowDef);
    this._footerRowDefChanged = true;
  }

  /** Sets a no data row definition that was not included as a part of the content children. */
  setNoDataRow(noDataRow: FmntsNoDataRowDirective | null): void {
    this._customNoDataRow = noDataRow;
  }

  /**
   * Updates the header styles.
   *
   * May be called manually for cases where the cell content changes outside
   * of these events.
   */
  updateHeaderRowStyles(): void {
    const headerRows = this._getRenderedRows(this._headerRowOutlet);
    const tableElement = this._elementRef.nativeElement;

    // Hide the thead element if there are no header rows. This is necessary to satisfy
    // overzealous a11y checkers that fail because the `rowgroup` element does not contain
    // required child `row`.
    const thead = tableElement.querySelector('thead');
    if (thead) {
      thead.style.display = headerRows.length ? '' : 'none';
    }
  }

  /**
   * Updates the footer styles.
   *
   * May be called manually for cases where the cell content changes outside
   * of these events.
   */
  updateFooterRowStyles(): void {
    const footerRows = this._getRenderedRows(this._footerRowOutlet);
    const tableElement = this._elementRef.nativeElement;

    // Hide the tfoot element if there are no footer rows. This is necessary to satisfy
    // overzealous a11y checkers that fail because the `rowgroup` element does not contain
    // required child `row`.
    const tfoot = tableElement.querySelector('tfoot');
    const tbody = tableElement.querySelector('tbody');
    if (tfoot) {
      if (tbody && !footerRows.length) {
        tbody.classList.add('last');
      }
      tfoot.style.display = footerRows.length ? '' : 'none';
    }
  }

  /**
   * Get the list of RenderRow objects to render according to the current list of data and defined
   * row definitions. If the previous list already contained a particular pair, it should be reused
   * so that the differ equates their references.
   */
  private _getAllRenderRows(): RenderRow<T>[] {
    const renderRows: RenderRow<T>[] = [];

    // Store the cache and create a new one. Any re-used RenderRow objects will be moved into the
    // new cache while unused ones can be picked up by garbage collection.
    const prevCachedRenderRows = this._cachedRenderRowsMap;
    this._cachedRenderRowsMap = new Map();

    // For each data object, get the list of rows that should be rendered, represented by the
    // respective `RenderRow` object which is the pair of `data` and `FmntsRowDef`.
    for (let i = 0; i < this._data.length; i++) {
      const data = this._data[i];
      const renderRowsForData = this._getRenderRowsForData(
        data,
        i,
        prevCachedRenderRows.get(data),
      );

      if (!this._cachedRenderRowsMap.has(data)) {
        this._cachedRenderRowsMap.set(data, new WeakMap());
      }

      for (const renderRow of renderRowsForData) {
        const cache = this._cachedRenderRowsMap.get(renderRow.data);
        if (cache?.has(renderRow.rowDef)) {
          cache?.get(renderRow.rowDef)?.push(renderRow);
        } else {
          cache?.set(renderRow.rowDef, [renderRow]);
        }
        renderRows.push(renderRow);
      }
    }

    return renderRows;
  }

  /**
   * Gets a list of `RenderRow<T>` for the provided data object and any `FmntsRowDef` objects that
   * should be rendered for this data. Reuses the cached RenderRow objects if they match the same
   * `(T, FmntsRowDef)` pair.
   */
  private _getRenderRowsForData(
    data: T,
    dataIndex: number,
    cache?: WeakMap<FmntsRowDefDirective<T>, RenderRow<T>[]>,
  ): RenderRow<T>[] {
    const rowDefs = this._getRowDefs(data, dataIndex);

    return rowDefs.map((rowDef) => {
      const cachedRenderRows = cache?.get(rowDef) ?? [];
      if (cachedRenderRows.length) {
        const dataRow = cachedRenderRows.shift() as RenderRow<T>;
        dataRow.dataIndex = dataIndex;
        return dataRow;
      } else {
        return { data, rowDef, dataIndex };
      }
    });
  }

  /** Update the map containing the content's column definitions. */
  private _cacheColumnDefs() {
    this._columnDefsByName.clear();

    const columnDefs = mergeArrayAndSet(
      this._getOwnDefs(this._contentColumnDefs),
      this._customColumnDefs,
    );
    columnDefs.forEach((columnDef) => {
      if (this._columnDefsByName.has(columnDef.name)) {
        throw getTableDuplicateColumnNameError(columnDef.name);
      }
      this._columnDefsByName.set(columnDef.name, columnDef);
    });
  }

  /** Update the list of all available row definitions that can be used. */
  private _cacheRowDefs() {
    this._headerRowDefs = mergeArrayAndSet(
      this._getOwnDefs(this._contentHeaderRowDefs),
      this._customHeaderRowDefs,
    );
    this._footerRowDefs = mergeArrayAndSet(
      this._getOwnDefs(this._contentFooterRowDefs),
      this._customFooterRowDefs,
    );
    this._rowDefs = mergeArrayAndSet(
      this._getOwnDefs(this._contentRowDefs),
      this._customRowDefs,
    );

    // After all row definitions are determined, find the row definition to be considered default.
    const defaultRowDefs = this._rowDefs.filter((def) => !def.when);
    if (defaultRowDefs.length > 1) {
      throw getTableMultipleDefaultRowDefsError();
    }
    this._defaultRowDef = defaultRowDefs[0];
  }

  /**
   * Check if the header, data, or footer rows have changed what columns they want to display or
   * whether the sticky states have changed for the header or footer. If there is a diff, then
   * re-render that section.
   */
  private _renderUpdatedColumns(): boolean {
    const columnsDiffReducer = (acc: boolean, def: BaseRowDef) =>
      acc || !!def.getColumnsDiff();

    // Force re-render data rows if the list of column definitions have changed.
    const dataColumnsChanged = this._rowDefs.reduce(columnsDiffReducer, false);
    if (dataColumnsChanged) {
      this._forceRenderDataRows();
    }

    // Force re-render header/footer rows if the list of column definitions have changed.
    const headerColumnsChanged = this._headerRowDefs.reduce(
      columnsDiffReducer,
      false,
    );
    if (headerColumnsChanged) {
      this._forceRenderHeaderRows();
    }

    const footerColumnsChanged = this._footerRowDefs.reduce(
      columnsDiffReducer,
      false,
    );
    if (footerColumnsChanged) {
      this._forceRenderFooterRows();
    }

    return dataColumnsChanged || headerColumnsChanged || footerColumnsChanged;
  }

  /**
   * Switch to the provided data source by resetting the data and unsubscribing from the current
   * render change subscription if one exists. If the data source is null, interpret this by
   * clearing the row outlet. Otherwise start listening for new data.
   */
  private _switchDataSource(dataSource: FmntsTableDataSourceInput<T>) {
    this._data = [];

    if (isDataSource(this.dataSource)) {
      this.dataSource.disconnect(this);
    }

    // Stop listening for data from the previous data source.
    if (this._renderChangeSubscription) {
      this._renderChangeSubscription.unsubscribe();
      this._renderChangeSubscription = null;
    }

    if (!dataSource) {
      if (this._dataDiffer) {
        this._dataDiffer.diff([]);
      }
      this._rowOutlet.viewContainer.clear();
    }

    this._dataSource = dataSource;
  }

  /** Set up a subscription for the data provided by the data source. */
  private _observeRenderChanges() {
    // If no data source has been set, there is nothing to observe for changes.
    if (!this.dataSource) {
      return;
    }

    let dataStream: Observable<readonly T[]> | undefined;

    if (isDataSource(this.dataSource)) {
      dataStream = this.dataSource.connect(this);
    } else if (isObservable(this.dataSource)) {
      dataStream = this.dataSource;
    } else if (Array.isArray(this.dataSource)) {
      dataStream = of(this.dataSource);
    }

    if (dataStream === undefined) {
      throw getTableUnknownDataSourceError();
    }

    this._renderChangeSubscription = dataStream
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this._data = data || [];
        this.renderRows();
      });
  }

  /**
   * Clears any existing content in the header row outlet and creates a new embedded view
   * in the outlet using the header row definition.
   */
  private _forceRenderHeaderRows() {
    // Clear the header row outlet if any content exists.
    if (this._headerRowOutlet.viewContainer.length > 0) {
      this._headerRowOutlet.viewContainer.clear();
    }

    this._headerRowDefs.forEach((def, i) =>
      this._renderRow(this._headerRowOutlet, def, i),
    );
    this.updateHeaderRowStyles();
  }

  /**
   * Clears any existing content in the footer row outlet and creates a new embedded view
   * in the outlet using the footer row definition.
   */
  private _forceRenderFooterRows() {
    // Clear the footer row outlet if any content exists.
    if (this._footerRowOutlet.viewContainer.length > 0) {
      this._footerRowOutlet.viewContainer.clear();
    }

    this._footerRowDefs.forEach((def, i) =>
      this._renderRow(this._footerRowOutlet, def, i),
    );
    this.updateFooterRowStyles();
  }

  /** Gets the list of rows that have been rendered in the row outlet. */
  _getRenderedRows(rowOutlet: RowOutlet): HTMLElement[] {
    const renderedRows: HTMLElement[] = [];

    for (let i = 0; i < rowOutlet.viewContainer.length; i++) {
      const viewRef = rowOutlet.viewContainer.get(i) as EmbeddedViewRef<any>;
      renderedRows.push(viewRef.rootNodes[0] as HTMLElement);
    }

    return renderedRows;
  }

  /**
   * Get the matching row definitions that should be used for this row data. If there is only
   * one row definition, it is returned. Otherwise, find the row definitions that has a when
   * predicate that returns true with the data. If none return true, return the default row
   * definition.
   */
  _getRowDefs(data: T, dataIndex: number): FmntsRowDefDirective<T>[] {
    if (this._rowDefs.length === 1) {
      return [this._rowDefs[0]];
    }

    const rowDefs: FmntsRowDefDirective<T>[] = [];

    const rowDef =
      this._rowDefs.find((def) => def.when && def.when(dataIndex, data)) ||
      this._defaultRowDef;
    if (rowDef) {
      rowDefs.push(rowDef);
    }

    if (!rowDefs.length) {
      throw getTableMissingMatchingRowDefError(data);
    }

    return rowDefs;
  }

  private _getEmbeddedViewArgs(
    renderRow: RenderRow<T>,
    index: number,
  ): _ViewRepeaterItemInsertArgs<RowContext<T>> {
    const rowDef = renderRow.rowDef;
    const context: RowContext<T> = { $implicit: renderRow.data };
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      templateRef: rowDef.template,
      context,
      index,
    };
  }

  /**
   * Creates a new row template in the outlet and fills it with the set of cell templates.
   * Optionally takes a context to provide to the row and cells, as well as an optional index
   * of where to place the new row template in the outlet.
   */
  private _renderRow(
    outlet: RowOutlet,
    rowDef: BaseRowDef,
    index: number,
    context: RowContext<T> = {},
  ): EmbeddedViewRef<RowContext<T>> {
    const view = outlet.viewContainer.createEmbeddedView<RowContext<T>>(
      rowDef.template as TemplateRef<RowContext<T>>,
      context,
      index,
    );
    this._renderCellTemplateForItem(rowDef, context);
    return view;
  }

  private _renderCellTemplateForItem(
    rowDef: BaseRowDef,
    context: RowContext<T>,
  ) {
    for (const cellTemplate of this._getCellTemplates(rowDef)) {
      if (FmntsCellOutletDirective.mostRecentCellOutlet) {
        FmntsCellOutletDirective.mostRecentCellOutlet._viewContainer.createEmbeddedView(
          cellTemplate,
          context,
        );
      }
    }

    this._changeDetectorRef.markForCheck();
  }

  /**
   * Updates the index-related context for each row to reflect any changes in the index of the rows,
   * e.g. first/last/even/odd.
   */
  private _updateRowIndexContext() {
    const viewContainer = this._rowOutlet.viewContainer;
    for (
      let renderIndex = 0, count = viewContainer.length;
      renderIndex < count;
      renderIndex++
    ) {
      const viewRef = viewContainer.get(renderIndex) as RowViewRef<T>;
      const context = viewRef.context;
      context.count = count;
      context.first = renderIndex === 0;
      context.last = renderIndex === count - 1;
      context.even = renderIndex % 2 === 0;
      context.odd = !context.even;

      context.index = this._renderRows[renderIndex].dataIndex;
    }
  }

  /** Gets the column definitions for the provided row def. */
  private _getCellTemplates(rowDef: BaseRowDef): TemplateRef<any>[] {
    if (!rowDef || !rowDef.columns) {
      return [];
    }
    return Array.from(rowDef.columns, (columnId) => {
      const column = this._columnDefsByName.get(columnId);

      if (!column) {
        throw getTableUnknownColumnError(columnId);
      }

      return rowDef.extractCellTemplate(column);
    });
  }

  /** Adds native table sections (e.g. tbody) and moves the row outlets into them. */
  private _applyNativeTableSections() {
    const documentFragment = this._document.createDocumentFragment();
    const sections = [
      { tag: 'thead', outlets: [this._headerRowOutlet] },
      { tag: 'tbody', outlets: [this._rowOutlet, this._noDataRowOutlet] },
      { tag: 'tfoot', outlets: [this._footerRowOutlet] },
    ];

    for (const section of sections) {
      const element = this._document.createElement(section.tag);
      element.setAttribute('role', 'rowgroup');

      for (const outlet of section.outlets) {
        element.appendChild(outlet.elementRef.nativeElement);
      }

      documentFragment.appendChild(element);
    }

    // Use a DocumentFragment so we don't hit the DOM on each iteration.
    this._elementRef.nativeElement.appendChild(documentFragment);
  }

  /**
   * Forces a re-render of the data rows. Should be called in cases where there has been an input
   * change that affects the evaluation of which rows should be rendered, e.g. toggling
   * `multiTemplateDataRows` or adding/removing row definitions.
   */
  private _forceRenderDataRows() {
    this._dataDiffer.diff([]);
    this._rowOutlet.viewContainer.clear();
    this.renderRows();
  }

  /** Filters definitions that belong to this table from a QueryList. */
  private _getOwnDefs<I extends { _table?: any }>(items: QueryList<I>): I[] {
    return items.filter((item) => !item._table || item._table === this);
  }

  /** Creates or removes the no data row, depending on whether any data is being shown. */
  private _updateNoDataRow() {
    const noDataRow = this._customNoDataRow || this._noDataRow;

    if (!noDataRow) {
      return;
    }

    const shouldShow = this._rowOutlet.viewContainer.length === 0;

    if (shouldShow === this._isShowingNoDataRow) {
      return;
    }

    const container = this._noDataRowOutlet.viewContainer;

    if (shouldShow) {
      const view = container.createEmbeddedView(noDataRow.templateRef);
      const rootNode = view.rootNodes[0] as HTMLElement | undefined;

      // Only add the attributes if we have a single root node since it's hard
      // to figure out which one to add it to when there are multiple.
      if (
        view.rootNodes.length === 1 &&
        rootNode?.nodeType === this._document.ELEMENT_NODE
      ) {
        rootNode.setAttribute('role', 'row');
        rootNode.classList.add(noDataRow._contentClassName);
      }
    } else {
      container.clear();
    }

    this._isShowingNoDataRow = shouldShow;

    this._changeDetectorRef.markForCheck();
  }
}

/** Utility function that gets a merged list of the entries in an array and values of a Set. */
function mergeArrayAndSet<T>(array: T[], set: Set<T>): T[] {
  return array.concat(Array.from(set));
}
