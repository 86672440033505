<div class="fmnts-toast-content__icon-surface">
  <div class="fmnts-toast-content__pulse"></div>
  <fmnts-icon-badge size="sm">
    <fa-icon [icon]="_normalizedBadgeIcon()" />
  </fmnts-icon-badge>
</div>
<div class="fmnts-toast-content__inner">
  <div class="fmnts-toast-content__text">
    <ng-content select="fmnts-toast-title" />
    <ng-content select="fmnts-toast-text" />
  </div>

  <ng-content select="[fmntsToastActions]" />
</div>
