<nav class="tab-bar" #nav>
  <div
    class="tab-bar__pagination"
    [class.tab-bar__pagination--active]="this.scrollableLeft$ | async"
    (click)="this.updateTabScrollPosition('left')"
  >
    <fa-icon
      [icon]="_iconScrollBack"
      class="tab-bar__pagination-chevron"
    ></fa-icon>
  </div>

  <div class="tab-bar__container" #tabsContainer>
    <div
      class="tab-bar__tabs"
      #tabs
      (click)="this.tabsInnerClicked($event)"
      data-testid="tab-bar__tabs"
    >
      <ng-content></ng-content>
    </div>
  </div>

  <div
    class="tab-bar__pagination"
    [class.tab-bar__pagination--active]="this.scrollableRight$ | async"
    (click)="this.updateTabScrollPosition('right')"
  >
    <fa-icon
      [icon]="_iconScrollForward"
      class="tab-bar__pagination-chevron"
    ></fa-icon>
  </div>
</nav>
