import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  Input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ToastType } from './toast.model';
import { TOAST_UI_ICON_OPTIONS } from './toast.tokens';

/**
 * Displays a toast.
 */
@Component({
  selector: 'fmnts-toast-content, [fmnts-toast-content]',
  templateUrl: './toast-content.component.html',
  styleUrls: ['./toast-content.component.scss'],
  standalone: true,
  imports: [FmntsIconsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToastContentComponent {
  private readonly icons = inject(TOAST_UI_ICON_OPTIONS);

  @HostBinding('class.fmnts-toast-content')
  private readonly componentClass = 'fmnts-toast-content';

  /** Type of the toast. */
  @Input({ required: true })
  get type(): ToastType {
    return this._type();
  }
  set type(value: ToastType) {
    this._type.set(value);
  }
  protected readonly _type = signal<ToastType>(null!);

  /** Icon to use for icon badge. */
  @Input()
  get badgeIcon(): IconProp | undefined {
    return this._badgeIcon();
  }
  set badgeIcon(value: IconProp | undefined | null) {
    this._badgeIcon.set(value ?? undefined);
  }
  private readonly _badgeIcon = signal<IconProp | undefined>(undefined);

  protected readonly _normalizedBadgeIcon = computed(
    () => this._badgeIcon() ?? this.icons.byType[this._type()],
  );

  @HostBinding('class') get hostClasses(): string {
    return classnames([`${this.componentClass}--${this._type()}`]);
  }
}
