import { InjectionToken, Injector, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { logger } from '@fmnts/common/log';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import * as Sentry from '@sentry/angular';
import type { LazyArg } from 'effect/Function';
import { isTruthy } from 'effect/Predicate';
import { first, map, pipe, tap, type Observable } from 'rxjs';

export type SentryClientOptionsResolver = LazyArg<
  Observable<Sentry.BrowserOptions>
>;

export const SENTRY_CLIENT_OPTIONS_RESOLVER =
  new InjectionToken<SentryClientOptionsResolver>(
    '@formunauts.shared.vendors.sentry.client-options-resolver',
  );

interface SentryRefState {
  /** Indicates if sentry ref is stable or unstable. */
  isStable: boolean;
}

/**
 * Reference to Sentry running on a page.
 */
export const SentryRef = signalStore(
  { protectedState: false }, withState<SentryRefState>(() => ({
    isStable: false,
  })),
  withMethods((store, log = logger({ name: 'SentryRef' })) => ({
    /**
     * Initializes sentry.
     *
     * **NOTE**: You probably don't need to call this directly.
     */
    init: rxMethod<Sentry.BrowserOptions>(
      pipe(
        tap((options) => {
          // Only init once
          if (!store.isStable()) {
            Sentry.init(options);
            log.debug('initialized', options);
            patchState(store, { isStable: true });
          }
        }),
        map(() => store.isStable()),
      ),
    ),
  })),
);

/**
 * Function to be used for initializers, e.g. `APP_INITIALIZER`.
 */
export function sentryRefInitializer(
  sentryRef = inject(SentryRef),
  config$ = inject(SENTRY_CLIENT_OPTIONS_RESOLVER)(),
  injector = inject(Injector),
): () => Observable<boolean> {
  return () => {
    // Directly initialize sentry and make sure that it is stable
    sentryRef.init(config$);
    return toObservable(sentryRef.isStable, { injector }).pipe(
      first<boolean>(isTruthy),
    );
  };
}
