import { InjectionToken } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faCircleInfo,
  faDiamondExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { ToastConfig, ToastType } from './toast.model';

/** Injection token that can be used to access the data that was passed in to a toast. */
export const TOAST_DATA = new InjectionToken<any>('ToastData');

/** Injection token that can be used to access the duration that was passed in to a toast. */
export const TOAST_DURATION = new InjectionToken<number | undefined>(
  'ToastDuration',
);

/** @docs-private */
export function FMNTS_TOAST_DEFAULT_OPTIONS_FACTORY(): ToastConfig {
  return new ToastConfig();
}

/** Injection token that can be used to specify default toast. */
export const FMNTS_TOAST_DEFAULT_OPTIONS = new InjectionToken<ToastConfig>(
  'fmnts-toast-default-options',
  {
    providedIn: 'root',
    factory: FMNTS_TOAST_DEFAULT_OPTIONS_FACTORY,
  },
);

interface ToastIconOptions {
  /** default icons by type */
  byType: Record<ToastType, IconDefinition>;
}
export const TOAST_UI_ICON_OPTIONS = new InjectionToken<ToastIconOptions>(
  '@fmnts.components.toast.icons',
  {
    factory: (): ToastIconOptions => ({
      byType: {
        error: faDiamondExclamation,
        success: faCircleCheck,
        warning: faTriangleExclamation,
        info: faCircleInfo,
      },
    }),
  },
);
