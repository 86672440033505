<div class="fmnts-select-label">
  <ng-content select="fmnts-label,label[fmnts-select-label]"></ng-content>
</div>

<div
  class="fmnts-select-input"
  (click)="toggleInput()"
  data-testid="select-input"
>
  @switch (value) {
    @case (_clearValue) {
      <div
        class="fmnts-select-input-placeholder"
        data-testid="select-input-placeholder"
      >
        {{ placeholder || '&ZeroWidthSpace;' }}
      </div>
    }
    @default {
      <div class="fmnts-select-input-value" data-testid="select-input-value">
        {{ formattedValue }}
      </div>
    }
  }

  <span
    class="fmnts-select-dropdown-toggle"
    [@fmntsIconRotateZ]="panelOpen ? 180 : 0"
  >
    <fa-icon [icon]="dropdownArrow"></fa-icon>
  </span>
</div>

<div
  #dropdown
  class="fmnts-select-dropdown"
  [style.display]="panelOpen ? undefined : 'none'"
  data-testid="select-dropdown"
>
  @if (showSearchField) {
    <div class="fmnts-select-type-ahead">
      <input
        #typeAhead
        type="text"
        [formControl]="typeAheadControl"
        placeholder="{{ typeAheadText }}…"
      />
    </div>
  }

  <div class="fmnts-select-options" data-testid="dropdown-select-options">
    <!-- Project option for deselecting -->
    @if (allowDeselect && value) {
      <ng-content select="fmnts-select-option[deselect]"></ng-content>
    }

    <!-- Project options with a value -->
    <ng-content></ng-content>

    <!-- Project option to show no-results info -->
    @if (!hasTypeaheadResult) {
      <ng-content select="fmnts-select-option[noAvailableOptions]"></ng-content>
    }
    @if (isLoadingOptions()) {
      <ng-content select="[isLoadingOptions]"></ng-content>
    }
  </div>
</div>
