<fmnts-modal-title>{{ 'leave_page_title' | translate }}</fmnts-modal-title>
<fmnts-modal-content>
  {{ 'leave_page_content' | translate }}
</fmnts-modal-content>
<fmnts-modal-actions>
  <button
    type="button"
    fmnts-button
    variant="plain"
    (click)="close('cancel')"
    data-testid="cancel"
  >
    {{ 'cancel' | translate }}
  </button>
  <button
    type="button"
    fmnts-button
    variant="filled"
    action="primary"
    (click)="close('ok')"
    data-testid="ok"
  >
    {{ 'ok' | translate }}
  </button>
</fmnts-modal-actions>
