/* eslint-disable @angular-eslint/component-selector */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';
import { ButtonBaseComponent } from './button-base';

export type IconButtonAction = 'primary' | 'normal';
export type IconButtonSize = 'sm' | 'md';

/**
 * Icon button component. This type of button displays a single interactive icon
 * for users to perform an action.
 */
@Component({
  selector: `button[fmnts-icon-button], a[fmnts-icon-button]`,
  template: `<ng-content />`,
  styleUrls: ['icon-button.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent extends ButtonBaseComponent {
  @Input() get action(): IconButtonAction {
    return this._action();
  }
  set action(value: IconButtonAction) {
    this._action.set(value);
  }
  private readonly _action = signal<IconButtonAction>('normal');

  /** Size of the button. */
  @Input()
  get size(): IconButtonSize {
    return this._size();
  }
  set size(value: IconButtonSize) {
    this._size.set(value);
  }
  private readonly _size = signal<IconButtonSize>('md');

  // Legacy inputs
  /**
   * @deprecated use {@link IconButtonComponent.action}.
   */
  @Input()
  set color(value: ThemeColor | undefined) {
    this._action.set(_mapToAction(value));
  }

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      `fmnts-icon-button`,
      `fmnts-icon-button--${this._size()}`,
      `fmnts-icon-button--${this.variant}-${this._action()}`,
    ]);
  }
}

function _mapToAction(value: ThemeColor | undefined): IconButtonAction {
  switch (value) {
    case 'primary':
      return 'primary';
    default:
      return 'normal';
  }
}
