import { inject } from '@angular/core';
import { AuthInfo } from '@fmnts/api/auth';
import { selectAuth } from '@fmnts/shared/auth/data-access';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { tap } from 'rxjs';

export const setSentryUserEffect = createEffect(
  () =>
    inject(Store)
      .select(selectAuth)
      .pipe(
        tap((auth) => {
          Sentry.setUser(auth ? mapAuthInfoToSentryUser(auth) : null);
        }),
      ),
  {
    functional: true,
    dispatch: false,
  },
);

/**
 * @param authInfo Auth info
 * @returns
 * Object to identify user on sentry
 */
function mapAuthInfoToSentryUser(authInfo: AuthInfo): Sentry.User {
  return {
    username: authInfo.username ?? undefined,
    // Custom properties
    customerId: authInfo.customerId,
    isActive: authInfo.isActive,
  };
}
